<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        fullscreen
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-app-bar height="100">
            <v-btn
              color="indigo"
              dark
              tile
              @click="save"
              class="mx-2"
              :disabled="change == 0"
            >
              Enregistrer
            </v-btn>

            <v-divider vertical class="mx-3"></v-divider>
            <v-btn
              color="indigo"
              dark
              tile
              @click="valider"
              v-if="fiche.statut_id == '18' && fiche.id > 0"
              :disabled="
                output_list.length == 0 ||
                palettes_list.findIndex((elm) => elm.closed == 0) >= 0
              "
            >
              Clotûrer
            </v-btn>
            <v-divider vertical class="mx-3"></v-divider>
            <v-toolbar-title>
              <h3>{{ formTitle }}</h3>
            </v-toolbar-title>
            <v-divider vertical class="mx-3"></v-divider>
            <v-select
              ref="agents"
              :items="agents"
              v-model="fiche.agent_id"
              item-text="nom"
              :item-value="'id'"
              label="Operateur"
              :readonly="readonly || ag_redonly || fiche.statut_id == '19'"
              :rules="[(v) => !!v || 'Operateur obligatoire']"
              dense
            >
            </v-select>

            <v-divider vertical class="mx-3"></v-divider>
            <span
              v-if="$store.state.auth.includes('04023') || $store.state.isadmin"
            >
              <datepicker
                label="Date Fiche"
                v-model="fiche.fiche_date"
                :edit="!readonly || fiche.statut_id == '18'"
                :rules="[(v) => !!v || 'Date obligatoire']"
                :clearable="false"
                :key="dp"
                @change="machine_change"
              ></datepicker>
            </span>
            <span v-else>
              <h3>{{ "Date : " + datefr(fiche.fiche_date) }}</h3>
            </span>

            <v-divider vertical class="mx-3"></v-divider>
            <v-select
              ref="shifts"
              :items="shifts"
              v-model="fiche.shift_id"
              item-text="label"
              item-value="id"
              label="Poste de Travail"
              :readonly="readonly || fiche.statut_id == '19'"
              :rules="[(v) => !!v || 'Poste de Travail obligatoire']"
              @change="change++"
            >
            </v-select>
            <v-divider vertical class="mx-3"></v-divider>
            <v-select
              ref="status"
              :items="[
                { id: '18', label: 'En cours' },
                { id: '19', label: 'Cloturé' },
              ]"
              v-model="fiche.statut_id"
              item-text="label"
              item-value="id"
              label="Statut"
              @change="change++"
            >
            </v-select>
            <v-divider vertical class="mx-3"></v-divider>
            <v-autocomplete
              v-model="fiche.machine_id"
              :item-text="'label'"
              :item-value="'id'"
              :items="machines_list"
              label="Machine"
              :rules="[(v) => !!v || 'Machine obligatoire']"
              @change="machine_change"
              v-if="fiche.id < 0"
              dense
            ></v-autocomplete>
            <span v-else>
              <h3>{{ "Machine : " + machine.label }}</h3>
            </span>
            <v-btn color="indigo" text @click.stop="close">
              <v-icon dark large> mdi-exit-to-app </v-icon></v-btn
            >
          </v-app-bar>
          <v-progress-linear
            v-if="progress"
            indeterminate
            :size="30"
            :width="7"
            color="purple"
          ></v-progress-linear>

          <!-- <v-card-title>
              {{ "Atelier : " + (bt.gamme_caption ? bt.gamme_caption : "") }}  
          </v-card-title> -->

          <v-card-text v-if="fiche.id > 0">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-alert
                    v-model="alert"
                    :type="type_alert"
                    dismissible
                    class="ma-2"
                    >{{ message }}</v-alert
                  >
                </v-col>
                <!--------- INTPUT -------->
                <v-col cols="12" sm="12" md="12">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-card v-if="fiche.statut_id == '18'" elevation="6">
                        <v-card-title>
                          <v-row>
                            <v-col cols="12" sm="3" md="3">
                              <v-select
                                class="mt-4"
                                ref="ofs"
                                :items="bts_list"
                                v-model="bt_id"
                                item-text="no_bt"
                                :item-value="'id'"
                                label="Current Job"
                                :readonly="
                                  readonly ||
                                  ag_redonly ||
                                  fiche.statut_id == '19'
                                "
                                @change="job_change"
                                dense
                                v-if="fiche.statut_id == '18'"
                              >
                                <template v-slot:selection="{ item }">
                                  <!-- HTML that describe how select should render selected items -->
                                  {{ item.no_bt }}
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-html="item.no_bt"
                                    ></v-list-item-title>

                                    <v-list-item-subtitle>
                                      {{
                                        "Statut : " +
                                        (item.statut_name
                                          ? item.statut_name
                                          : "")
                                      }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                      {{
                                        "Order : " +
                                        (item.job_order ? item.job_order : "")
                                      }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-select>
                            </v-col>
                            <!-- Bouton ajouter -->
                            <v-col cols="12" sm="2" md="2">
                              <v-btn
                                ref="save"
                                color="indigo"
                                class="mx-1"
                                dark
                                @click="close_job"
                                :disabled="add_disable || !bt_id"
                              >
                                <v-icon large>mdi-check</v-icon>
                                {{
                                  job.statut_id == 7 ? "Cloturer" : "Decloturer"
                                }}
                              </v-btn>
                            </v-col>
                            <v-col cols="12" sm="1" md="1">
                              <v-text-field
                                autocomplete="off"
                                :ref="'nb_etiq'"
                                dense
                                v-model.number="nb_etiq"
                                label="Etq"
                                hide-spin-buttons
                                @focus="$event.target.select()"
                                solo-inverted
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-divider></v-divider>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-card
                            v-if="fiche.statut_id == '18'"
                            elevation="6"
                            :key="pkey"
                          >
                            <v-card-text v-if="job.statut_id == 7">
                              <v-row
                                v-for="product in products"
                                :key="product.id"
                              >
                                <v-col cols="12" sm="8" md="8">
                                  {{ product.label }}
                                </v-col>
                                <v-col cols="12" sm="2" md="2">
                                  <v-text-field
                                    autocomplete="off"
                                    :ref="'qte'"
                                    dense
                                    v-model.number="product.qte"
                                    label="Quantité"
                                    hide-spin-buttons
                                    @focus="$event.target.select()"
                                    @keydown.enter="add_output(product)"
                                    solo-inverted
                                  ></v-text-field>
                                </v-col>

                                <!-- Bouton ajouter -->
                                <v-col cols="12" sm="2" md="2">
                                  <v-btn
                                    ref="save"
                                    color="indigo"
                                    class="mx-1"
                                    dark
                                    @click="add_output(product)"
                                    :disabled="
                                      add_disable || !product.qte || !pal_id
                                    "
                                  >
                                    <v-icon large>mdi-arrow-down-box</v-icon>
                                    Ajouter
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                          <h1>{{ "Palette Actuelle " + actual_pal }}</h1>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <listitems
                            :list="palettes_list"
                            :headers="palheaders"
                            :title="'Palettes'"
                            :qDelete="qPaletteDelete"
                            :selitem="selpal"
                            :master="true"
                            :add="true"
                            :del="fiche.statut_id == '18'"
                            :Get_suf="'Palette'"
                            :showedit="false"
                            :showstd="false"
                            :print="false"
                            :exp_excel="false"
                            :Total="true"
                            :key="kpal"
                            @open="palette_open"
                            @col_btn1_click="palette_close"
                            @delete="palette_delete"
                            @rowselect="PalSelect"
                            @col_btn2_click="print_palette_ticket"
                            :laoding="listload"
                            :ipg="15"
                          >
                          </listitems>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <listitems
                        :list="output_list"
                        :headers="outputheaders"
                        :title="'Production'"
                        :qUpdate="qFichedetail"
                        :master="true"
                        :add="false"
                        :selitem="selitem"
                        :del="false"
                        :del_disable="output.qte_cons > 0"
                        :Get_suf="'FicheDetail'"
                        :Update="fiche.statut_id == '18'"
                        :showedit="false"
                        :showstd="false"
                        :Total="true"
                        :print="false"
                        :exp_excel="false"
                        :key="frml"
                        @rowselect="OutputSelect"
                        @col_btn2_click="print_ticket"
                        @col_btn1_click="delete_item"
                        @col_btn3_click="declasse_item"
                        :laoding="listload"
                        :ipg="15"
                      >
                      </listitems>
                    </v-col>
                  </v-row>
                </v-col>
                <!--------- OUTPUT -------->
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>

    <confirmdialog ref="confirm" :is_motif="is_motif" @motif="get_motif" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <paletteform
      :palette="palette"
      :fiche="item"
      :list="palettes_list"
      :showForm="isPalette"
      :key="kpf"
      @close="isPalette = false"
      @palette_add="palette_add"
    ></paletteform>
  </div>
</template>

<script>
let time = new Date().toISOString().slice(11, 19);
import FICHE from "../graphql/Production/FICHE.gql";

import CREATE_FICHE from "../graphql/Production/CREATE_FICHE.gql";
import UPDATE_FICHE from "../graphql/Production/UPDATE_FICHE.gql";

import CREATE_PALETTE from "../graphql/Production/CREATE_PALETTE.gql";
import UPDATE_PALETTE from "../graphql/Production/UPDATE_PALETTE.gql";
import DELETE_PALETTE from "../graphql/Production/DELETE_PALETTE.gql";

import INSERT_FICHE_DETAIL from "../graphql/Production/INSERT_FICHE_DETAIL.gql";
import DELETE_FICHE_DETAIL from "../graphql/Production/DELETE_FICHE_DETAIL.gql";

import UPDATE_FICHE_DETAIL from "../graphql/Production/UPDATE_FICHE_DETAIL.gql";
import UPDATE_BT from "../graphql/Production/UPDATE_BT.gql";

import PRODUCTION_LIST from "../graphql/Production/PRODUCTION_LIST.gql";
import { myName } from "print/data.js";
import jsPDF from "jspdf";
import axios from "axios";
import ALLBTS from "../graphql/Production/ALLBTS.gql";
import { ticket } from "print/ticket.js";
import QRCode from "qrcode";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    listitems: () => import("./ListItems.vue"),
    datepicker: () => import("./DatePicker.vue"),
    paletteform: () => import("./PaletteForm.vue"),
  },
  name: "fichepf",
  props: {
    item: Object,
    shifts: Array,
    agents: Array,
    machines: Array,
    showForm: Boolean,
  },
  data: () => ({
    dp: 1500,
    kpal: 1600,
    change: 0,
    nb_etiq: 2,
    readonly: false,
    last_poids: null,
    glist: false,
    imp: false,
    isPalette: false,
    title: "Liste Produit",
    listload: false,
    list: false,
    progress: false,
    kq: 3000,
    kpf: 4500,
    pkey: 3600,
    valid: true,
    valid1: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    product: {},
    editedItem: {},
    palettes_list: [],
    products: [],
    machines_list: [],
    bt_id: null,
    bts: [],
    job: {},
    atelier: false,
    weight: null,
    defaultItem: {
      id: -1,
      fiche_date: null,
      statut_name: "Brouillon",
      statut_id: "18",
      details: [],
      palettes: [],
    },
    fiche: {},
    intrant: {},
    qte: 0,
    tol: 0,

    palette: {},

    output: {},
    detail_load: false,
    add_disable: false,
    ag_redonly: false,
    palette_items: [],
    tare: 0,
    unit: "",
    tol_rcpt: 0,
    prc_fab: 0,
    unit_category: null,
    sell_net: null,
    last_palette: null,
    palheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "ref",
        slot: "href",
        selected: true,
      },
      {
        text: "Date",
        value: "created_at",
        selected: true,
      },
      {
        text: "Quantité",
        value: "qte_brut",
        total: "qte_brut",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Poids Net (Kg)",
        value: "qte_net",
        total: "qte_net",
        slot: "cur",
        align: "end",
        hiden: true,
        selected: true,
        dec: 2,
      },
      {
        text: "Poids Palette (Kg)",
        value: "poids",
        total: "poids",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "NB.",
        value: "nb_items",
        total: "nb_items",
        slot: "cur",
        align: "end",
        dec: 0,
        selected: true,
      },
      {
        text: "",
        slot: "col_btn2",
        icon: "mdi-printer",
        column: "closed",
        valeur: 1,
        selected: true,
      },
      {
        text: "",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-close-circle-multiple-outline",
        tooltip: "Cloturer la palette",
        column: "closed",
        valeur: 0,
        sortable: false,
        width: "1%",
        index: 26,
      },
      {
        text: "",
        slot: "col_btn1",
        selected: true,
        icon: "mdi-restore",
        tooltip: "Restorer la palette",
        column: "closed",
        valeur: 1,
        sortable: false,
        width: "1%",
        index: 27,
      },
    ],
    outputheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Code",
        value: "ref",
        selected: true,
        expire: true,
      },
      {
        text: "Produit",
        value: "label",
        selected: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },
      {
        text: "Poids Net (Kg)",
        value: "qte_net",
        total: "qte_net",
        slot: "cur",
        align: "end",
        hiden: true,
        selected: true,
        dec: 2,
      },
      {
        text: "Unité",
        value: "unit",
        selected: false,
      },
      {
        text: "Date",
        value: "created_at",
        selected: true,
      },
      {
        text: "Palette",
        value: "palette",
        selected: true,
      },
      {
        text: "N° Cmd",
        value: "nocmd",
        selected: false,
      },
      {
        text: "Client",
        value: "client_name",
        selected: false,
      },
      {
        text: "Comment",
        value: "comment",
        selected: false,
      },
      {
        text: "",
        slot: "col_btn2",
        icon: "mdi-printer",
        selected: true,
      },
      {
        text: "",
        slot: "col_btn1",
        icon: "mdi-delete",
        column: "qte_cons",
        valeur: 0,
        selected: true,
      },
      {
        text: "",
        slot: "col_btn3",
        icon: "mdi-alert-plus",
        column: "conforme",
        valeur: "1",
        selected: true,
      },
      {
        text: "",
        slot: "col_btn3",
        icon: "mdi-check",
        column: "conforme",
        valeur: "0",
        selected: true,
      },
    ],
    expheaders: [
      {
        text: "Code",
        value: "ref",
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        selected: true,
      },
      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        dec: 2,
      },

      {
        text: "Date",
        value: "created_at",
        selected: true,
      },
    ],
    currentTab: 0,
    output_list: [],
    bom: [],
    frml: 600,
    frml2: 800,
    frml3: 900,
    frml4: 700,
    kl: 1900,
    kfo: 1000,
    input_detail: [],
    products_out: [],
    product_items: [],
    articles: [],
    machine: {},
    product_in: {},
    selitem: {},
    selpal: {},
    alert: false,
    type_alert: "info",
    message: "",
    agent: {},
    num_seq: 1,
    is_motif: false,
    actual_pal: "",
    pal_id: null,
  }),
  watch: {},
  computed: {
    bts_list() {
      let list = [];
      list = this.bts.filter((elm) => elm.machine_id == this.fiche.machine_id);
      return list;
    },
    qPaletteCreate() {
      return CREATE_PALETTE;
    },
    qPaletteDelete() {
      return DELETE_PALETTE;
    },

    qFichedetail() {
      return UPDATE_FICHE_DETAIL;
    },

    Qintrantupdate() {
      return UPDATE_FICHE_DETAIL;
    },
    code_produit() {
      return this.$store.state.options[0].code_produit;
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Fiche "
          : "Fiche N° " + this.fiche.nofiche;
      else return "";
    },
  },

  created() {},
  async mounted() {
    if (this.machines) this.machines_list = this.machines;
    if (this.item) {
      if (this.item.id > 0) {
        this.fiche = Object.assign({}, this.item);
        this.readonly = this.fiche.statut_id == 19;
        this.palettes_list = this.fiche.palettes_sup;

        this.actual_palette();
        this.kpal++;
        this.dp++;

        this.list_fiche();
        this.job_change();
      } else {
        this.defaultItem.fiche_date = new Date().toISOString().slice(0, 10);
        this.fiche = Object.assign({}, this.defaultItem);
        let i = this.shifts.findIndex(
          (elm) => elm.time_from <= time && elm.time_to >= time
        );
        if (i >= 0) this.fiche.shift_id = this.shifts[i].id;
        if (this.machines_list.length == 1) {
          this.fiche.machine_id = this.machines_list[0].machine_id;
        }
      }
      this.palettes_refresh();
      this.outputheaders[13].hiden =
        this.fiche.statut_id != "18" ||
        !(
          this.$store.state.auth.includes("04115") || this.$store.state.isadmin
        );
      this.machine_change();
      if (
        this.$store.state.me.agent_id &&
        !this.$store.state.isadmin &&
        !this.$store.state.auth.includes("04023")
      ) {
        if (this.item.id < 0) {
          this.fiche.agent_id = this.$store.state.me.agent_id;
        }
        this.ag_redonly = true;
      }
    }
  },

  methods: {
    async machine_change() {
      let i = this.machines.findIndex((elm) => elm.id == this.fiche.machine_id);

      if (i >= 0) {
        this.machine = this.machines[i];
        this.change++;
      }
      if (this.fiche.machine_id && this.fiche.fiche_date) {
        let w = {
          AND: [
            { column: "STATUT_ID", value: 7 },
            { column: "MACHINE_ID", value: this.fiche.machine_id },
          ],
        };
        let v = {
          where: w,
        };
        this.isloading = true;
        let r = await this.requette(ALLBTS, v);
        this.isloading = false;
        if (r) {
          this.bts = r.allbts;
          if (this.bts.length > 0) {
            this.bt_id = this.bts[0].id;
            this.job_change();
          }
        }
      }
    },
    verify_poids() {
      this.palheaders[3].text = "Quantité";
      this.outputheaders[3].text = "Quantité";
      this.palheaders[4].hiden = true;
      this.outputheaders[4].hiden = true;
      let i = this.output_list.findIndex((elm) => elm.unit_category == 2);
      if (i >= 0) {
        this.palheaders[3].text = "Poids Brut (Kg)";
        this.palheaders[4].hiden = false;
        this.outputheaders[3].text = "Poids Brut (Kg)";
        this.outputheaders[4].hiden = false;
      }
    },

    get_products() {
      this.products = this.job.details;
      this.pkey++;
    },
    actual_palette() {
      this.last_palette = this.palette;
      this.actual_pal = "Introuvable";
      this.pal_id = null;
      let i = this.palettes_list.findIndex((elm) => elm.closed == 0);
      if (i >= 0) {
        this.actual_pal = this.palettes_list[i].ref;
        this.pal_id = this.palettes_list[i].id;
        this.selpal = this.palettes_list[i];
      }
    },
    PalSelect(item) {
      this.last_palette = this.palette;
      this.palette = item;
      this.palette_items = this.palette.items;
      if (item.closed == 0) {
        this.actual_pal = item.ref;
        this.pal_id = item.id;
      }
    },

    job_change() {
      let i = this.bts_list.findIndex((elm) => elm.id == this.bt_id);
      if (i >= 0) {
        this.job = this.bts_list[i];
        this.get_products();
      }
    },
    get_motif(m) {
      this.motif = m;
    },

    customFilter(item, queryText) {
      let textlist = [];
      let l = [
        "product_code",
        "product_label",
        "article_code",
        "article_label",
        "label",
        "nocmd",
        "tier_name",
        "ref",
        "code",
      ];

      l.forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },

    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    async palette_add(item) {
      this.last_poids = item.poids;
      this.actual_palette();
      this.kpal++;
    },
    palette_open(item) {
      this.palette = item;
      if (item.id < 0) this.palette.poids = this.last_poids;
      this.isPalette = true;
      this.kpf++;
    },
    palette_delete() {
      this.actual_palette();
    },
    async insert_output(product) {
      //
      let v = {
        create_uid: this.$store.state.me.id,
        fiche_id: this.fiche.id,
        cmd_id: this.job.cmd_id,
        pal_id: this.pal_id,
        bt_id: this.bt_id,
        list: [
          {
            article_id: product.article_id,
            cmd_det_id: product.cmd_det_id,
            qte: product.qte,
            comment: product.comment,
          },
        ],
      };
      let r = await this.maj(INSERT_FICHE_DETAIL, v);
      return r;
    },

    sortBy(prop) {
      return function (a, b) {
        return a[prop] > b[prop] ? 1 : -1;
      };
    },
    async close_job() {
      let s = this.job.statut_id == 7 ? "cloturer" : "decloturer";
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir " + s + " ce job?"
        )
      ) {
        await this.maj(UPDATE_BT, {
          bt: { id: this.bt_id, statut_id: this.job.statut_id == 7 ? 9 : 7 },
        });
        this.job.statut_id = this.job.statut_id == 7 ? 9 : 7;
      }
    },
    async refresh() {
      let r2 = await this.requette(FICHE, {
        where: { column: "ID", value: this.fiche.id },
      });
      if (r2) {
        this.fiche = r2.allfiches[0];

        this.output_list = this.fiche.details;
        this.palettes_refresh();
        this.PalSelect(this.palette);

        this.verify_poids();
        this.listload = false;
        this.frml++;
      }
    },
    async add_output(product) {
      if (
        product.cmd_det_id &&
        !this.add_disable &&
        product.qte &&
        this.pal_id
      ) {
        let ok = true;
        this.add_disable = true;
        this.alert = false;
        // verifier client palette
        let list = this.output_list.filter((elm) => elm.pal_id == this.pal_id);

        let i = list.findIndex((elm) => elm.client_id != this.job.tier_id);
        if (i >= 0) {
          this.message = "Palette multi-client interdite-changer de palette ";
          this.type_alert = "error";
          this.alert = true;
          ok = false;
        }
        // // verifier les quantites necessaire de l'intrants
        // if (
        //   product.qte + product.qte_fab >
        //   product.qte_cmd +
        //     parseFloat(
        //       ((product.qte_cmd * this.tol_rcpt) / 100).toFixed(2)
        //     )
        // ) {
        //   this.message = "Quantité à fabriquer autorisée dépassée ";
        //   this.type_alert = "warning";
        //   this.alert = true;
        // }
        let r;

        if (ok) {
          r = await this.insert_output(product);
          if (r) {
            if (r.ok) {
              // let j = this.products.findIndex(
              //   (elm) => elm.id == product.cmd_det_id
              // );
              // if (j >= 0) {
              //   this.products[j].rest_fab =
              //     this.products[j].rest_fab - parseFloat(product.qte);
              // }

              let d = JSON.parse(r.data.InsertFicheDetail);

              if (d) {
                if (d.length > 0) {
                  this.refresh();
                  let j = this.output_list.findIndex(
                    (elm) => elm.id == d[0].id
                  );

                  if (j >= 0) {
                    this.print_ticket(this.output_list[j], this.nb_etiq);
                  }
                  this.$store.dispatch("Changed", true);
                  let i = this.palettes_list.findIndex(
                    (elm) => elm.id == this.pal_id
                  );
                  if (i >= 0) {
                    this.palettes_list[i].nb_items =
                      (this.palettes_list[i].nb_items
                        ? this.palettes_list[i].nb_items
                        : 0) + 1;
                    this.palettes_list[i].qte =
                      (this.palettes_list[i].qte
                        ? this.palettes_list[i].qte
                        : 0) + product.qte;
                    this.palettes_list[i].qte_net =
                      (this.palettes_list[i].qte_net
                        ? this.palettes_list[i].qte_net
                        : 0) +
                      product.qte -
                      product.tare;
                    // product.qte_fab =
                    //   product.qte_fab +
                    //   product.qte -
                    //   (this.sell_net == 1 ? this.tare : 0);
                    // this.prc_fab = (
                    //   (product.qte_fab / product.qte_cmd) *
                    //   100
                    // ).toFixed(2);
                  }

                  product.qte = null;

                  this.frml2++;
                  this.add_disable = false;

                  this.$refs.form.resetValidation();
                }
              }
            }
            if (!r.ok) {
              let s = r.error.graphQLErrors[0].debugMessage;
              if (
                s.search("1062") > 0 &&
                s.search("productions_fiches_details_index02") > 0
              ) {
                let s1 = s.indexOf("'", 0);
                let s2 = s.indexOf("'", s1 + 1);
                let k = s.substring(s1 + 1, s2);
                this.message = "Code " + k + " existe déja";
              } else this.message = "Erreur d'enregistrement inconnue ";
              this.type_alert = "error";
              this.alert = true;
              this.add_disable = false;
            }
          } else this.add_disable = false;
        } else this.add_disable = false;
      }
    },
    async declasse_item(item) {
      if (item.conforme == 0 && !this.last_palette) {
        this.snackbar_text = "Aucune Palette pour restaurer cet element!";
        this.snackbar_color = "error";
        this.snackbar = true;
        return;
      }

      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir " +
            (item.conforme == 1 ? "declasser" : "restorer ") +
            " cet element" +
            (item.conforme == 0
              ? " dans la palette " + this.last_palette.ref
              : "") +
            " ?"
        )
      ) {
        let v = {
          fichedetail: {
            id: item.id,
            conforme: item.conforme == 1 ? 0 : 1,
            pal_id: item.conforme == 1 ? null : this.last_palette.id,
          },
        };
        let r = await this.maj(UPDATE_FICHE_DETAIL, v);
        if (r.ok) {
          item.pal_id = item.conforme == "1" ? null : this.last_palette.id;
          item.conforme = item.conforme == "1" ? "0" : "1";
          if (item.conforme == "0") {
            this.palette_items.splice(this.palette_items.indexOf(item), 1);
            let i = this.palettes_list.findIndex(
              (elm) => elm.id == this.palette.id
            );
            if (i >= 0) {
              this.palettes_list[i].nb_items =
                this.palettes_list[i].nb_items - 1;
            }
            i = this.palettes_list.findIndex((elm) => elm.id == -1);
            if (i >= 0) {
              this.palettes_list[i].items.unshift(item);
              this.palettes_list[i].nb_items =
                this.palettes_list[i].nb_items + 1;
            }
          }
          if (item.conforme == "1") {
            this.palette_items.splice(this.palette_items.indexOf(item), 1);
            let i = this.palettes_list.findIndex((elm) => elm.id == -1);
            if (i >= 0) {
              this.palettes_list[i].nb_items =
                this.palettes_list[i].nb_items - 1;
            }

            i = this.palettes_list.findIndex(
              (elm) => elm.id == this.last_palette.id
            );
            if (i >= 0) {
              this.palettes_list[i].items.unshift(item);
              this.palettes_list[i].nb_items =
                this.palettes_list[i].nb_items + 1;
            }
          }

          this.frml++;
          this.snackbar_text = "Mise a jour effectuée";
          this.snackbar_color = "success";
          this.snackbar = true;
        } else {
          this.snackbar_text = "Erreur de mis a jour";
          this.snackbar_color = "error";
          this.snackbar = true;
        }
      }
    },
    palettes_refresh() {
      this.palettes_list = this.fiche.palettes_sup.filter(
        (elm) =>
          (this.allpalette ? true : elm.closed == 0) &&
          (elm.qte == 0 || elm.qte_rest > 0)
      );
      let items = this.fiche.details.filter((elm) => elm.conforme == 0);
      this.palettes_list.push({
        id: -1,
        ref: "Declasse",
        nb_items: items.length,
        items: items,
      });
      this.kpal++;
      let i = this.palettes_list.findIndex((elm) => elm.id == this.palette.id);
      if (i >= 0) {
        this.palette = this.palettes_list[i];
      }
    },
    async delete_item(item) {
      let ok = true;
      this.alert = false;
      let v = {
        pfd_id: item.id,
      };
      let r = await this.requette(PRODUCTION_LIST, v);
      if (r) {
        let i = r.production_list.findIndex(
          (elm) => elm.qte_cons > 0 || elm.consomed == "1"
        );
        if (i >= 0) {
          this.message = "Production consommée--Suppression impossible";
          this.type_alert = "error";
          this.alert = true;
          ok = false;
        }
      }
      if (ok)
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer cet element?"
          )
        ) {
          v = {
            id: item.id,
          };
          r = await this.maj(DELETE_FICHE_DETAIL, v);
          if (r.ok) {
            let r2 = await this.requette(FICHE, {
              where: { column: "ID", value: this.fiche.id },
            });
            if (r2) {
              this.fiche = r2.allfiches[0];
              this.frml2++;
              let i = this.palettes_list.findIndex(
                (elm) => elm.id == item.pal_id
              );
              if (i >= 0) {
                this.palettes_list[i].nb_items--;
                this.palettes_list[i].qte =
                  this.palettes_list[i].qte - item.qte;
                this.palettes_list[i].qte_net =
                  (this.palettes_list[i].qte_net
                    ? this.palettes_list[i].qte_net
                    : 0) - item.qte_net;
              }

              this.output_list = this.fiche.details;
              this.verify_poids();
              this.listload = false;
              this.frml++;
            }
            this.$store.dispatch("Changed", true);
          } else {
            this.message = "Erreur de suppression";
            this.type_alert = "error";
            this.alert = true;
          }
        }
    },

    OutputSelect(item) {
      this.output = item;
    },

    async palette_close(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir " +
            (item.closed == 0 ? "clôturer" : "restorer") +
            " cette palette?"
        )
      ) {
        let r = await this.maj(UPDATE_PALETTE, {
          palette: { id: item.id, closed: item.closed == 0 ? 1 : 0 },
        });
        if (r.ok) {
          item.closed = item.closed == 0 ? 1 : 0;
          this.kpal++;
          this.selpal = item;
          if (item.closed == 1) {
            this.actual_palette();
          }
        }
      }
    },

    async print_ticket(item, nb = 1) {
      try {
        const qrCodeDataURL = await QRCode.toDataURL(item.ref);
        const pdfBlob = ticket(item, qrCodeDataURL);

        const formData = new FormData();
        formData.append("file", pdfBlob, "label.pdf");
        formData.append("copies", nb); // Send the number of copies as a string

        await axios.post("http://localhost:3000/print", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      } catch (error) {
        console.error("Erreur lors de l'impression :", error);
        alert("Échec de l'impression !");
      }
    },

    async print_palette_ticket(item) {
      const doc = new jsPDF();
      this.palette_items = item.items;
      // Group and accumulate quantities and count items
      const groupedItems = this.palette_items.reduce((acc, product) => {
        const existing = acc.find((p) => p.label === product.label);

        if (existing) {
          existing.qte += parseFloat(product.qte.toFixed(2)); // Cumulate quantity
          existing.qte_net += parseFloat(product.qte_net.toFixed(2)); // Cumulate quantity
          existing.nbItems += 1; // Count items
        } else {
          acc.push({
            client_name: product.client_name || "Unknown Client",
            label: product.label || "Unknown Product",
            unit_colis: product.unit_colis || "Unknown Unit",
            qte: parseFloat(product.qte.toFixed(2)), // Initialize quantity
            qte_net: parseFloat(product.qte_net.toFixed(2)), // Initialize quantity
            nbItems: 1, // Initialize item count
            unit: product.unit || "", // Keep unit consistent
          });
        }
        return acc;
      }, []);

      // Ticket Header
      const paletteCode = item.ref || "Unknown Ref";
      const date = item.created_at || new Date().toLocaleDateString();

      // Add QR Code
      let label = "";
      let client_name = "";
      let unit_colis = "";
      let unit = "";
      let nb_unit_colis = 0;
      groupedItems.forEach((product) => {
        label = label + product.label + "\n ";
        client_name = product.client_name;
        if (unit_colis == "" || unit_colis != product.unit_colis) {
          unit_colis = product.unit_colis;
          nb_unit_colis++;
        }
        if (unit == "" || unit != product.unit) {
          unit = product.unit;
        }
      });
      const qrCodeDataURL = await QRCode.toDataURL(
        paletteCode + "\n " + "Date Fabrication " + date + "\n" + label
      );
      doc.setFontSize(16);

      doc.setFont("helvetica", "bold");
      doc.text(`${myName}`, 70, 10);
      doc.addImage(qrCodeDataURL, "PNG", 10, 10, 30, 30); // Adjust size and position
      doc.setFont("helvetica", "normal");
      // Add Palette Information
      doc.setFontSize(12);
      doc.text(`N° Palette: ${paletteCode}`, 50, 15);
      doc.text(`Date: ${date}`, 50, 20);
      doc.text(`Client: ${client_name}`, 50, 25);
      // Table Data Preparation
      const tableData = groupedItems.map((product) => [
        product.label, // Product Label
        product.nbItems +
          (nb_unit_colis > 1
            ? " " + product.unit_colis + (product.nbItems > 1 ? "s" : "")
            : ""), // Number of Items
        `${this.numberWithSpace(product.qte.toFixed(2))} ${product.unit}`, // Total Quantity (cumulated)
        `${this.numberWithSpace(product.qte_net.toFixed(2))} ${product.unit}`, // Total Quantity (cumulated)
      ]);

      let h = ["Produit", "Nombre d'items", "Brut", "Net"];
      let w = this.output_list.findIndex((elm) => elm.unit_category == 2) == -1;
      let columnStyles = {
        0: { cellWidth: 80 }, // Label column width
        1: { cellWidth: 20, halign: "center" }, // Number of Items alignment
        2: { cellWidth: 40, halign: "center" }, // Total Quantity alignment
        3: { cellWidth: 40, halign: "center" }, // Total Quantity alignment
      };
      if (w) {
        h = ["Produit", "Nombre d'items", "Quantité"];
        columnStyles = {
          0: { cellWidth: 100 }, // Label column width
          1: { cellWidth: 20, halign: "center" }, // Number of Items alignment
          2: { cellWidth: 60, halign: "center" }, // Total Quantity alignment
        };
      }
      if (nb_unit_colis == 1) h[1] = "Nombre de " + unit_colis;
      // Add Table with AutoTable
      doc.autoTable({
        startY: 50, // Start position below the header
        head: [h],
        body: tableData,
        styles: {
          fontSize: 10, // Font size for table content
          cellPadding: 2, // Cell padding
        },
        columnStyles: columnStyles,
        headStyles: {
          fillColor: [128, 128, 128],
          textColor: [0, 0, 0], // Header text color
          fontSize: 11, // Font size for header
          halign: "center", // Center align header text
          valign: "middle",
        },
        bodyStyles: {
          textColor: [0, 0, 0], // Body text color
        },
      });

      // Footer: Total Summary
      let totalbrut = item.qte_brut ? item.qte_brut : 0;

      const totalnet = item.qte_net ? item.qte_net : 0;
      const totalItems = groupedItems.reduce(
        (sum, product) => sum + product.nbItems,
        0
      );

      const finalY = doc.lastAutoTable.finalY + 10; // Position after the table
      doc.setFontSize(12);
      let c = "Total Items";
      if (nb_unit_colis == 1) c = "Total " + unit_colis;
      doc.text(`${c}: ${totalItems}`, 10, finalY);
      if (!w) {
        c = "Poids Brut";
        totalbrut = item.qte_brut;
      } else {
        c = "Quantité";
        totalbrut = item.qte;
      }
      c = !w ? "Poids Brut" : "Quantité";
      doc.text(
        `${c} : ${this.numberWithSpace(totalbrut.toFixed(2))} ${unit || ""}`,
        80,
        finalY
      );
      if (!w)
        doc.text(
          `Poids Net : ${this.numberWithSpace(totalnet.toFixed(2))} ${
            unit || ""
          }`,
          150,
          finalY
        );

      // Open PDF for printing or saving
      window.open(doc.output("bloburl")); // Open in a new tab for printing
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },

    async list_fiche() {
      this.output_list = [];
      if (this.fiche.id >= 0) {
        this.listload = true;
        this.output_list = this.fiche.details;
        this.verify_poids();

        this.listload = false;
        this.frml++;
      }
    },

    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = { ok: true, data: data.data };
          this.progress = false;
        })
        .catch((error) => {
          r = { ok: false, error: error };
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      this.progress = true;

      let r = [];

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async close() {
      this.$emit("close", this.fiche);
    },
    async valider() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir clotûrer cette fiche?"
        )
      ) {
        this.fiche.statut_id = 19;
        let v = {
          fiche: {
            id: this.fiche.id,
            statut_id: this.fiche.statut_id,
            write_uid: this.$store.state.me.id,
          },
        };

        await this.maj(UPDATE_FICHE, v, true);
      }
    },
    async save() {
      this.alert = false;
      if (this.$refs.form.validate()) {
        let v;
        if (this.fiche.id > 0) {
          v = {
            fiche: {
              id: this.fiche.id,
              shift_id: this.fiche.shift_id,
              agent_id: this.fiche.agent_id,
              machine_id: this.fiche.machine_id,
              fiche_date: this.fiche.fiche_date,
              statut_id: this.fiche.statut_id,
              write_uid: this.$store.state.me.id,
            },
          };

          await this.maj(UPDATE_FICHE, v, true);
          this.$store.dispatch("Changed", true);
        } else {
          let v = {
            fiche: {
              fiche_type: 2,
              fiche_date: this.fiche.fiche_date,
              shift_id: this.fiche.shift_id,
              agent_id: this.fiche.agent_id,
              machine_id: this.fiche.machine_id,
              statut_id: this.fiche.statut_id,

              create_uid: this.$store.state.me.id,
              write_uid: this.$store.state.me.id,
            },
          };
          v = await this.maj(CREATE_FICHE, v);
          if (v.ok) {
            this.fiche.id = v.data.createFiche.id;
            this.fiche.nofiche = v.data.createFiche.nofiche;

            this.$store.dispatch("Changed", true);
          }
        }
        this.$emit("refresh", this.fiche);
        this.change = 1;
      }
    },
  },
};
</script>

<style scoped>
.green-icon .v-icon {
  color: green;
}
.orange-icon .v-icon {
  color: orange;
}
.red-icon .v-icon {
  color: red;
}
.blue-icon .v-icon {
  color: rgb(21, 21, 197);
}
</style>
